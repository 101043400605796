import Img from "gatsby-image";
import styled from "styled-components";

import { Container } from "@util/standard";
import {
  colors,
  TABLET_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TWENTY_INCH_SCREEN,
  IMAC_BREAKPOINT,
  SMALLER_MOBILE,
} from "@util/constants";

export const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  position: absolute !important;
`;

export const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  position: absolute !important;
  object-fit: cover;
`;

export const Overlay = styled.div<{ opacity: number }>`
  position: absolute;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    86.25deg,
    rgba(6, 15, 37, 0.62) 49.25%,
    rgba(155, 169, 203, 0.05) 149.18%
  );
  opacity: ${({ opacity }) => opacity && `${opacity};`};
`;

export const StyledContainer = styled(Container)`
  position: absolute;
  left: 8%;
  bottom: 13%;

  @media only screen and (min-width: ${TWENTY_INCH_SCREEN}px) {
    top: 52%;
    left: 10%;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: 25%;
    left: 12%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    top: 20%;
    left: 15%;
  }

  @media only screen and (max-width: ${SMALLER_MOBILE}px) {
    top: 20%;
    left: 10%;
  }
`;

export const HeroHeaderProxy = styled.div`
  width: 100%;
  height: 100%;
`;

export const CustomBlockContent = styled.div`
  color: ${colors.white};
  width: 83%;
  h2 {
    width: 80%;
    font-family: "bold";
    margin-top: 15px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 30px;
  }

  strong {
    display: block;
    font-family: "bold";
    font-size: 14px;
    width: 85%;
  }

  p {
    color: ${colors.white} !important;
    font-size: 14px;
    line-height: 20px;
    margin: 12px 0px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-left: 20px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 0px;
    width: 86%;
    h2 {
      font-size: 20px;
      margin-top: 10px;
      width: 100%;
      margin-bottom: auto;
    }
    p,
    strong {
      font-size: 12px;
    }
  }
`;

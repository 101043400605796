import React, { useEffect, useState } from "react";

import { Maybe, SanityHero } from "@graphql-types";
import { Container, GridContainer, H1 } from "@util/standard";
import { BlocksContent, IconButton, Video } from "@sub";

import {
  StyledVideo,
  CustomBlockContent,
  StyledImg,
  StyledContainer,
  HeroHeaderProxy,
  Overlay,
} from "./hero.styles";
import { TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  heroContent: Maybe<SanityHero> | undefined;
  headerIsVisible?: boolean;
  isHistoryPage?: boolean;
  isHomePage?: boolean;
  fullHeight?: boolean;
  gradientOpacity?: number;
  linkToHomePage?: boolean;
}

function Hero(props: Props) {
  const {
    heroContent,
    headerIsVisible,
    isHomePage,
    linkToHomePage,
    fullHeight,
    gradientOpacity,
  } = props;
  if (heroContent == null) {
    return null;
  }

  const {
    heroHeader,
    heroSubText,
    heroBackgroundImage,
    heroMobileBackgroundImage,
    heroVideo,
    useVideo,
    ctaScrollButtonText,
  } = heroContent;

  const [isTabletWidth, setIsTabletWidth] = useState(false);

  const handleScroll = () => {
    if (typeof window !== undefined) {
      const scrollValue = window.innerHeight;
      window.scrollTo({ top: scrollValue, behavior: "smooth" });
    }
    return;
  };

  const checkScreenWidth = () => {
    if (window.innerWidth <= TABLET_BREAKPOINT) {
      setIsTabletWidth(true);
    } else {
      setIsTabletWidth(false);
    }
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      checkScreenWidth();
      window.addEventListener("resize", checkScreenWidth);
    }

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  });

  return (
    <Container
      width="100vw"
      height={fullHeight ? "100vh" : "91vh"}
      tabletHeight={fullHeight ? "100vh" : "91vh"}
      mobileHeight={fullHeight ? "100vh" : "95vh"}
      backgroundColor="navy"
      position="relative"
    >
      {useVideo && heroVideo ? (
        <Video
          id={heroVideo.vimeoUrl}
          url={heroVideo.vimeoUrl}
          volume={false}
          pause={true}
          autoPlayOnLoad={true}
        />
      ) : (
        <StyledImg
          fluid={
            isTabletWidth && heroMobileBackgroundImage
              ? heroMobileBackgroundImage?.asset?.fluid
              : heroBackgroundImage?.asset?.fluid
          }
        />
      )}
      {/* OVERLAY DIV TO ADD BACKGROUND FILTER TO THE IMAGE */}
      <Overlay opacity={gradientOpacity} />
      <StyledContainer>
        <GridContainer
          gridTemplateColumns="repeat(2, 1fr)"
          width="80%"
          minWidth="850px"
          maxWidth="1280px"
          tabletWidth="85%"
          tabletMinWidth="0px"
          mobileWidth="83%"
          mobileMinWidth="250px"
          tabletRepeat={1}
          columnGap={10}
        >
          {headerIsVisible || isTabletWidth ? (
            <Container
              margin="10px 18px 0px 20px"
              mobileMargin="20px 18px 0px 0px"
            >
              <H1
                opacity={0.3}
                fontSize={90}
                lineHeight={90}
                noMargin
                mobileFontSize={45}
                mobileLineHeight={45}
              >
                {heroHeader?.toUpperCase()}
              </H1>
              {ctaScrollButtonText && !linkToHomePage && !isTabletWidth && (
                <IconButton
                  margin="50px 0px 0px 0px"
                  mobileMargin="0px"
                  buttonText={isTabletWidth ? "More" : ctaScrollButtonText}
                  reversed
                  icon="downIcon"
                  onClick={handleScroll}
                />
              )}
            </Container>
          ) : (
            //   PROXY DIV TO MAINTAIN SPACING WHEN HEADER IS INCLUDED IN BACKGROUND IMAGE
            <HeroHeaderProxy></HeroHeaderProxy>
          )}
          <CustomBlockContent>
            {heroSubText && <BlocksContent blocks={heroSubText._rawContent} />}
          </CustomBlockContent>
        </GridContainer>
        {ctaScrollButtonText && !linkToHomePage && isTabletWidth && (
          <IconButton
            margin="20px 0px 0px 20px"
            mobileMargin="0px"
            buttonText={isTabletWidth ? "More" : ctaScrollButtonText}
            reversed
            icon="downIcon"
            onClick={handleScroll}
          />
        )}
        {ctaScrollButtonText && linkToHomePage && isTabletWidth && (
          <IconButton
            margin="20px 0px 0px 20px"
            mobileMargin="0px"
            buttonText={isTabletWidth ? "More" : ctaScrollButtonText}
            reversed
            icon="downIcon"
            url="/"
          />
        )}
      </StyledContainer>
    </Container>
  );
}

export default Hero;

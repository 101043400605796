import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Hero from "@components/shared/hero/hero";

function Error404() {
  const { sanityErrorLanding } = useStaticQuery(graphql`
    {
      sanityErrorLanding {
        errorHero {
          heroHeader
          ctaScrollButtonText
          heroBackgroundImage {
            asset {
              fluid {
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Hero heroContent={sanityErrorLanding.errorHero} headerIsVisible fullHeight linkToHomePage />
    </>
  );
}

export default Error404;